export const GET_TESTIMONIALS = "GET_TESTIMONIALS";
export const GET_TESTIMONIAL = "GET_TESTIMONIAL";

export const GET_PERSON = "GET_PERSON";
export const GET_PEOPLE = "GET_PEOPLE";

export const GET_BLOG_POST = "GET_BLOG_POST";
export const GET_BLOG_POSTS = "GET_BLOG_POSTS";

export const GET_PROJECTS = "GET_PROJECTS";
export const SET_PROJECT_ORDER = "SET_PROJECT_ORDER";

export const SET_LOADING = "SET_LOADING";
